import React from 'react';
import { Container } from 'react-bootstrap';

function Blog() {
  return (
    <Container>
      <h1>Under Construction!</h1>
      <p>(She's a busy gal)</p>
    </Container>
  );
}

export default Blog;
